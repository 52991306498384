import React from "react";
import "./TermsOfUse.css";

const TermsOfUse: React.FC = () => {
  return (
    <div className="termsofuse">
      <h1>Nutzungsbedingungen</h1>
        <p>
        Mit der Teilnahme an Crossviews erklärst du dich damit
        einverstanden, uns deine E-Mail-Adresse, deinen Namen, dein Alter, deine Angabe zu 
        Beruf/Studienfach und deine
        Antworten auf die im Formular angegebenen Fragen zur Verfügung zu
        stellen.
        </p>
        <p>
        Wir verwenden diese Daten, um dich mit einem Dialogpartner zu
        matchen. Deine E-Mail, dein Name und die Antworten auf die Fragen
        werden an deinen Dialogpartner weitergegeben, damit ihr in Kontakt
        treten könnt.
        </p>
        <p>
        Abgesehen davon werden deine Daten nicht an Dritte weitergegeben.
        </p>
    </div>
  );
};

export default TermsOfUse;
