import React from "react";
import { Link } from "react-router-dom";
import "./About.css";

const About: React.FC = () => {
  return (
    <div className="about">
      <h1>
        Über <strong>Crossviews</strong> – Die Initiative für politischen Dialog
      </h1>
      <p>
        {" "}
        In einer Zeit, in der politische Debatten zunehmend polarisieren und
        Menschen mit unterschiedlichen Ansichten seltener in einen konstruktiven
        Austausch treten, haben wir <strong>Crossviews</strong> ins Leben
        gerufen. Unser Ziel ist es, Menschen mit gegensätzlichen politischen
        Positionen zusammenzubringen, um Verständnis, Respekt und vielleicht
        sogar gemeinsame Standpunkte zu fördern.
      </p>

      <h2>Wer steckt hinter Crossviews?</h2>
      <p>
        {" "}
        Wir sind eine kleine Gruppe von Menschen in Heidelberg. Wir sind besorgt
        über die zunehmende Polarisierung in der Gesellschaft und möchten einen
        Beitrag zu einem respektvollen und konstruktiven politischen Diskurs
        leisten. <br />
        Wir sind unabhängig von jeglichen politischen Parteien.
      </p>

      <h2>
        Wie funktioniert <strong>Crossviews</strong>?
      </h2>
      <p>
        {" "}
        Zunächst beantwortest Du eine kurze Reihe von Ja-Nein-Fragen zu
        aktuellen politischen Themen. Wir suchen dann nach einem Dialog-Match –
        jemandem, der oder die möglichst entgegengesetzte Ansichten vertritt.
        Sobald wir eine solche Person gefunden haben, senden wir euch beiden
        eure Kontakt-Mailadresse zu. Ihr trefft euch dann - persönlich oder
        digital - und diskutiert über die Fragen.
      </p>

      <h2>Warum teilnehmen?</h2>
      <ul>
        <li>
          Komm raus aus Deiner Bubble! Lerne andere Ansichten und Meinungen
          kennen, für die Du bisher kein Verständnis hast.
        </li>
        <li>
          Erzähle anderen was Dich bewegt. Vermittle Menschen, die dir sonst
          nicht zuhören würden, wie Du zu Deinen Überzeugungen gekommen bist.
        </li>
        <li>
          Trainiere Deine Fähigkeit, zuzuhören, zu verstehen und zu überzeugen.
        </li>
        <li>
          Trage zu einer Kultur der Offenheit und des Respekts in politischen
          Diskussionen bei.
        </li>
      </ul>

      <h2>Wie kann ich starten?</h2>
      <p>
        Ganz einfach! Beantworte die <Link to="/">Ja-Nein-Fragen</Link>, um
        loszulegen. Sobald Du fertig bist, kümmern wir uns um die Suche nach
        Deinem politischen Gegenstück.
      </p>

      <h2>Was kostet das ganze?</h2>
      <p>
        Nichts! Wir sind eine unabhängige, nicht-kommerzielle Plattform. Es ist
        uns ein Anliegen, Menschen ins Gespräch miteinander zu bekommen. Daher
        fallen zu keinem Zeitpunkt Kosten für die Teilnahme an.
      </p>

      <h2>Bereit für den Dialog?</h2>
      <p>
        Dann beantworte jetzt die <Link to="/">Ja-Nein-Fragen</Link>!
      </p>
    </div>
  );
};

export default About;
