import Overview from "./Overview";
import Registration from "./Registration";
import "./Home.css";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Home = ({ paramSent, setParamSent }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const p = searchParams.get("p");

    if (!paramSent) {
      fetch(process.env.REACT_APP_TRACKING_LINK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          p,
          environment: process.env.REACT_APP_ENV,
        }),
      });
      setParamSent(true);
    }
    if (p) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.delete("p");
      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams, paramSent, setParamSent]);

  return (
    <div className="middle">
      <Overview />
      <Registration />
    </div>
  );
};

export default Home;
