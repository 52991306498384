import { useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./Registration.css";

type FormState = {
  email: string;
  username: string;
  answers: Array<{
    id: number;
    text: string;
    isActive: boolean;
    answer: boolean | null;
  }>;
  age: string;
  job: string;
};

const Registration = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const questions = [
    // {
    //   id: 1,
    //   text: "Soll die Ukraine stärker als bisher unterstützt werden?",
    //   isActive: false,
    // },
    // {
    //   id: 2,
    //   text: "Soll Migration nach Deutschland reduziert werden?",
    //   isActive: false,
    // },
    // {
    //   id: 3,
    //   text: "Soll sich die EU ambitioniertere Klimaziele setzen?",
    //   isActive: false,
    // },
    // {
    //   id: 4,
    //   text: "Soll die EU eine Steuer für Superreiche einführen?",
    //   isActive: false,
    // },
    // {
    //   id: 5,
    //   text: "Soll ein Verbot der AfD geprüft werden?",
    //   isActive: false,
    // },
    {
      id: 12,
      text: "Soll ein bedingungsloses Grundeinkommen eingeführt werden?",
      isActive: true,
    },
    {
      id: 6,
      text: "Soll das Budget für Entwicklungszusammenarbeit erhöht werden?",
      isActive: true,
    },
    // {
    //   id: 7,
    //   text: "Soll Deutschland den Euro abschaffen und eine nationale Währung einführen?",
    //   isActive: false,
    // },
    {
      id: 9,
      text: "Soll in Mensen und Kantinen überwiegend veganes Essen angeboten werden?",
      isActive: true,
    },
    {
      id: 10,
      text: "Soll Agrardiesel weiterhin steuerlich begünstigt werden?",
      isActive: true,
    },
    {
      id: 11,
      text: "Soll die EU langfristig zu einer föderalen europäischen Republik ausgebaut werden?",
      isActive: true,
    },
    {
      id: 8,
      text: "Soll eine Vermögenssteuer für Superreiche eingeführt werden?",
      isActive: true,
    },
    // {
    //   id: 13,
    //   text: "Soll die EU Einfuhrzölle auf chinesische Elektroautos verhängen?",
    //   isActive: true,
    // },
    {
      id: 14,
      text: "Ist die Marktwirtschaft das richtige Wirtschaftssystem?",
      isActive: true,
    },
  ];
  const [formState, setFormState] = useState<FormState>({
    email: "",
    username: "",
    answers: questions.map((e) => ({ ...e, answer: null })),
    age: "",
    job: "",
  });

  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, email: e.target.value });
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, username: e.target.value });
  };

  const handleAnswerChange = (index, answer) => {
    setFormState((currentFormState) => {
      // Create a new array with the current items
      let newAnswers = [...currentFormState.answers];

      // Replace the object at the given index with a new object
      newAnswers[index] = {
        ...newAnswers[index],
        answer, // Update the name property
      };

      return { ...currentFormState, answers: newAnswers }; // Return the new array as the new state
    });
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, age: e.target.value });
  };

  const handleJobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, job: e.target.value });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const changeApproval = () => {
    setAcceptTermsOfUse((currentAccept) => !currentAccept);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setSubmitting(true);
      const response = await fetch(process.env.REACT_APP_REGISTRATION_LINK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formState),
      });
      setSubmitted(true);
      setSubmitting(false);
      if (!response.ok) throw new Error("Network response was not ok.");
      // Handle success
    } catch (error) {
      console.error("Failed to send data", error);
    }
  };

  return submitted ? (
    <div className="middleright">
      <h1>Erfolg</h1>
      <p>
        Danke für Deine Anmeldung! Wir haben Dir soeben eine E-Mail zur
        Verifizierung geschickt. Öffne nun Dein E-Mail-Postfach um die Anmeldung
        zu bestätigen.
      </p>
      <p>
        <b>
          Falls du nichts bekommen hast, prüfe, ob die E-Mail in Deinem
          Spam-Ordner gelandet ist.{" "}
        </b>
        Ansonsten melde Dich umgehend bei uns:{" "}
        <a href="mailto:kontakt@crossviews.de">kontakt@crossviews.de</a>
      </p>
      <p>
        Sobald Du registriert bist, suchen wir nach einem Diskussions-Match für
        Dich. Wenn wir jemanden gefunden haben und Ihr beide das Match annehmt,
        könnt Ihr euch kontaktieren, treffen und Eure Diskussion beginnen.
      </p>
    </div>
  ) : submitting ? (
    <div className="middleright">
      <h1>Einen Moment</h1>
      <p style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </p>
      <p>Wir bearbeiten deine Anfrage...</p>
    </div>
  ) : (
    <div className="middleright">
      <h1>Jetzt mitmachen!</h1>
      <p>
        Beantworte die Fragen. Wir suchen dann nach einem Match – jemandem, der
        oder die möglichst entgegengesetzte Ansichten vertritt.
      </p>
      {questions.map((question, index) => (
        <div key={index}>
          <p>{question.text}</p>
          <div className="buttons">
            <Button
              style={{
                backgroundColor:
                  formState.answers[index].answer === true
                    ? "#51c3b7"
                    : "white",
                color:
                  formState.answers[index].answer === true
                    ? "white"
                    : "#757070",
                marginRight: 10,
              }}
              onClick={() => handleAnswerChange(index, true)}
            >
              Ja
            </Button>
            <Button
              style={{
                backgroundColor:
                  formState.answers[index].answer === false
                    ? "#51c3b7"
                    : "white",
                color:
                  formState.answers[index].answer === false
                    ? "white"
                    : "#757070",
                marginLeft: 10,
              }}
              onClick={() => handleAnswerChange(index, false)}
            >
              Nein
            </Button>
          </div>
        </div>
      ))}
      <div>
        <TextField
          id="outlined-basic"
          label="E-Mail-Adresse"
          className="textField"
          variant="outlined"
          type="email"
          value={formState.email}
          onChange={handleEmailChange}
          style={{
            margin: 5,
            marginTop: 20,
          }}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": {
                borderColor: "white", // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#51c3b7", // border color when focused
              },
            },
          }}
        />
        <TextField
          id="outlined-basic"
          label="Name"
          className="textField"
          variant="outlined"
          type="username"
          value={formState.username}
          onChange={handleUsernameChange}
          style={{ margin: 5, marginTop: 20 }}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": {
                borderColor: "white", // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#51c3b7", // border color when focused
              },
            },
          }}
        />
      </div>

      <div>
        <TextField
          id="outlined-basic"
          label="Alter"
          className="textField"
          variant="outlined"
          type="age"
          value={formState.age}
          onChange={handleAgeChange}
          style={{
            margin: 5,
            marginTop: 20,
          }}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": {
                borderColor: "white", // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#51c3b7", // border color when focused
              },
            },
          }}
        />
        <TextField
          id="outlined-basic"
          label="Beruf/Studienfach o.ä."
          className="textField"
          variant="outlined"
          type="job"
          value={formState.job}
          onChange={handleJobChange}
          style={{ margin: 5, marginTop: 20 }}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": {
                borderColor: "white", // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#51c3b7", // border color when focused
              },
            },
          }}
        />
      </div>

      <div className="termsOfUse">
        <Checkbox
          onClick={() => changeApproval()}
          sx={{
            color: "white",
          }}
        />
        <span>
          Ich akzeptiere die{" "}
          <a onClick={toggleDrawer(true)}>Nutzungsbedingungen</a>.
        </span>
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
          <IconButton
            onClick={toggleDrawer(false)}
            style={{ position: "absolute", right: 5, top: 5 }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            className="drawerBox"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <h2>Nutzungsbedingungen</h2>
            <p>
              Mit der Teilnahme an Crossviews erklärst du dich damit
              einverstanden, uns deine E-Mail-Adresse, deinen Namen und deine
              Antworten auf die im Formular angegebenen Fragen zur Verfügung zu
              stellen.
            </p>
            <p>
              Wir verwenden diese Daten, um dich mit einem Dialogpartner zu
              matchen. Deine E-Mail, dein Name und die Antworten auf die Fragen
              werden an deinen Dialogpartner weitergegeben, damit ihr in Kontakt
              treten könnt.
            </p>
            <p>
              Abgesehen davon werden deine Daten nicht an Dritte weitergegeben.
            </p>
          </Box>
        </Drawer>
      </div>
      <div className="submitButton">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={
            !(
              formState.email &&
              formState.username &&
              formState.answers.every((e) => e.answer !== null) &&
              acceptTermsOfUse
            )
          }
        >
          Abschicken
        </Button>
      </div>
    </div>
  );
};

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default Registration;
