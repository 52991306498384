import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./About";
import Approve from "./Approve";
import Match from "./Match";
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import logo from "./crossviews_logo2t.png";
import { useState } from "react";

function App() {
  const [paramSent, setParamSent] = useState(false);
  return (
    <Router>
      <div className="App">
        <div className="top">
          <Link to="/">
            <img src={logo} alt="crossviews logo" style={{ height: "68px" }} />
          </Link>
          <div>
            <Link to="/" style={{ margin: "0 20px" }}>
              Start
            </Link>
            <Link to="/about">Über uns</Link>
          </div>
        </div>
        <Routes>
          <Route
            path="/"
            element={<Home paramSent={paramSent} setParamSent={setParamSent} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/approve/:uuid" element={<Approve />} />
          <Route path="/match/:uuid" element={<Match />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
        <div className="bottom">
          <footer className="footer">
            <div>
              <Link to="/imprint">Impressum</Link>{" "}
              <Link to="/privacy" style={{ margin: "0 20px" }}>
                Datenschutzerklärung
              </Link>{" "}
              <Link to="/terms-of-use">Nutzungsbedingungen</Link>
            </div>
            <span style={{ marginLeft: "auto", color: "#2eb4c6" }}>
              Made 2024 in Heidelberg{" "}
              <img src="/castle_emoji.svg" style={{ height: "1.2em" }} />
            </span>
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;
