import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Approve.css";
import CircularProgress from "@mui/material/CircularProgress";

function Approve() {
  const { uuid } = useParams();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_APPROVAL_LINK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: uuid,
      }),
    })
      .then((_) => setVerified(true))
      .catch((error) => console.error("There was an error!", error));
  }, [uuid]);
  return verified ? (
    <div className="approve">
      <h1>Verifizierung abgeschlossen</h1>
      <p>
        Du hast Deine Teilnahme bestätigt. Wir geben Dir Bescheid, sobald wir
        ein Diskussionsmatch für Dich gefunden haben.
      </p>
    </div>
  ) : (
    <div className="approve">
      <h1>Einen Moment</h1>
      <p>
        <CircularProgress />
      </p>
      <p>Wir bearbeiten deine Anfrage...</p>
    </div>
  );
}

export default Approve;
