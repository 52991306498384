import React from "react";
import "./Imprint.css";

const Imprint: React.FC = () => {
  return (
    <div className="imprint">
      <h1>Impressum</h1>
      <div>
        <h2>Name</h2>
        <p>
          Crossviews GbR<br></br>
          Belfortstr. 9<br></br>
          69115 Heidelberg
        </p>

        <h2>Vertreten durch:</h2>
        <p>
          Frederik Holtel<br></br>
          Thomas Rekers<br></br>
        </p>

        <h2>Kontakt</h2>
        <p>E-Mail: kontakt@crossviews.de</p>

        <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h2>Quelle:</h2>
        <p>
          <a href="https://www.e-recht24.de/impressum-generator.html">
            https://www.e-recht24.de/impressum-generator.html
          </a>
        </p>

        <h2>Bildquellen</h2>
        <p>
          Die Grafik auf der Startseite ist von <a href="https://www.freepik.com/free-vector/brainstorming-concept-landing-page_5566837.htm">Freepik</a>.
        </p>
      </div>
    </div>
  );
};

export default Imprint;
