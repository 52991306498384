import { useState } from "react";
import { useParams } from "react-router-dom";
import "./Match.css";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

function Match() {
  const { uuid } = useParams();
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [matchAccepted, setMatchAccepted] = useState(null);

  const handleClick = (accepted) => {
    setMatchAccepted(accepted);
    fetch(process.env.REACT_APP_MATCH_LINK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: uuid,
        matchAccepted: accepted,
      }),
    })
      .then((_) => setRequestCompleted(true))
      .catch((error) => console.error("There was an error!", error));
  };
  return requestCompleted === true && matchAccepted === true ? (
    <div className="match">
      <h1>Bestätigung</h1>
      <p>
        Du hast Dein Match akzeptiert. Sobald beide Personen des Matches das
        Match akzeptiert haben, wird Dir die E-Mail-Adresse Deiner
        Match-Partnerin oder Deines Match-Partners zugeschickt, um einen Termin
        zu vereinbaren.
      </p>
    </div>
  ) : requestCompleted === true && matchAccepted === false ? (
    <div className="match">
      <h1>Bestätigung</h1>
      <p>Du hast Dein Match abgelehnt.</p>
    </div>
  ) : requestCompleted === false && matchAccepted !== null ? (
    <div className="match">
      <h1>Einen Moment</h1>
      <p>
        <CircularProgress />
      </p>
      <p>Wir bearbeiten deine Eingabe...</p>
    </div>
  ) : (
    <div className="match">
      <p>Hier kannst Du Dein Match akzeptieren oder ablehnen:</p>
      <div className="buttons">
        <Button
          style={{
            backgroundColor: "#51c3b7",
            color: "white",
            marginRight: 10,
          }}
          onClick={() => handleClick(true)}
        >
          Match Akzeptieren
        </Button>
        <Button
          style={{
            backgroundColor: "#51c3b7",
            color: "white",
            marginLeft: 10,
          }}
          onClick={() => handleClick(false)}
        >
          Match Ablehnen
        </Button>
      </div>
    </div>
  );
}

export default Match;
