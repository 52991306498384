import React from "react";
import "./Privacy.css";

const Privacy: React.FC = () => {
  return (
    <div className="privacy">
      <h1>Datenschutzerklärung</h1>
      <p>
        Im Folgenden informieren wir Sie darüber, welche personenbezogenen Daten
        (d.h. Daten, die einen Bezug zu Ihrer Person ermöglichen, wie z.B. Name,
        Adresse oder IP-Adresse) wir auf dieser Website erheben und zu welchem
        Zweck und wie lange diese Daten gespeichert werden. Anschließend
        informieren wir Sie über Ihre Rechte bezüglich der Datenverarbeitung und
        wie Sie diese Rechte geltend machen können.
      </p>

      <p>
        <strong>1. Verantwortlichkeit für die Datenverarbeitung</strong>
      </p>

      <p>
        Verantwortlich für jegliche Datenverarbeitung im Zusammenhang mit dieser
        Website ist
      </p>

      <p>Crossviews GbR</p>

      <p>vertreten durch Frederik Holtel und Thomas Rekers</p>

      <p>Belfortstraße 9, 69115 Heidelberg</p>

      <p>Email: kontakt@crossviews.de</p>

      <p>
        <strong>
          2. Erhobene Daten, Zwecke der Verarbeitung, Rechtsgrundlage der
          Verarbeitung und Speicherdauer
        </strong>
      </p>

      <p>a.) Anmeldeformular</p>

      <p>
        Auf unserer Website haben Sie die Möglichkeit, sich zu registrieren um
        einen Gesprächspartner oder eine -partnerin zu finden.
      </p>

      <p>
        Um sich mit dem entsprechenden Anmeldeformular anzumelden, müssen Sie
        uns Ihren Namen, die Kontakt-E-Mail-Adresse und Ihre Antworten auf
        mehrere inhaltliche, politische Fragen mitteilen. Außerdem müssen Sie
        ein Passwort wählen.
      </p>

      <p>
        Wir speichern die von Ihnen zur Verfügung gestellten personenbezogenen
        Daten ausschließlich zur Bearbeitung Ihrer Bewerbung und zur Erfüllung
        eines eventuell geschlossenen Vertrages. Wir verarbeiten Ihre Daten
        auch, um Ihnen Informationen über Crossviews-Veranstaltungen zuzusenden.
        Die Bereitstellung der vorgenannten Daten ist für einen möglichen
        Vertragsabschluss erforderlich. Die Rechtsgrundlage für die
        Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. b) GDPR.
      </p>

      <p>
        Wir speichern die Daten, bis die Bearbeitung der Bewerbung abgeschlossen
        ist und &#8211; sofern die Bewerbung erfolgreich war &#8211; solange ein
        persönliches Konto bei uns und damit ein Vertragsverhältnis besteht.
        Nach einer Beendigung des Vertragsverhältnisses speichern wir die Daten
        bis zum Ablauf der gesetzlichen Verjährungsfristen. Wir haben ein
        berechtigtes Interesse an der Speicherung Ihrer Daten in
      </p>

      <p>b.) Automatisch erhobene Daten</p>

      <p>
        Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum
        Einsatz kommenden Browser automatisch Informationen an den Server
        unserer Website gesendet. Diese Informationen werden temporär in einem
        sogenannten Logfile gespeichert.
      </p>

      <p>
        Folgende Informationen werden automatisch erfasst (IP-Adresse, Datum und
        Uhrzeit des Zugriffs, Name und URL der abgerufenen Datei, Webseite, von
        der aus der Zugriff erfolgt (Referrer-URL), die http-Methode, der
        Statuscode, die Datenmenge der Server-Antwort, der verwendete Browser
        und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres
        Access-Providers.
      </p>

      <p>
        Die vorgenannten Daten werden von uns zu administrativen Zwecken und zur
        Gewährleistung eines reibungslosen Verbindungsaufbaus und einer
        komfortablen Nutzung unserer Website sowie zur Gewährleistung der
        Sicherheit und Stabilität unseres Systems verarbeitet.
      </p>

      <p>
        Die IP-Adresse, d.h. die einzige Information, die einen Bezug zu Ihrer
        Person herstellen könnte, wird nach zwei Wochen anonymisiert, so dass
        ein Personenbezug nicht mehr herstellbar ist.
      </p>

      <p>
        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
        lit. f) GDPR.
      </p>

      <p>
        <strong>3. Empfänger der personenbezogenen Daten</strong>
      </p>

      <p>
        Wir geben prinzipiell keine Daten an Dritte weiter. Ausnahmen bestehen
        dort, wo dies für den Versand der Informationen notwendig ist. Für das
        Hosting unserer Website und der entsprechenden Daten sowie für den
        Versand von Informations- und Werbemails bedienen wir uns
        Dienstleistern. Die Dienstleister verarbeiten die ihnen von uns zur
        Verfügung gestellten Daten zur Erfüllung der Zwecke ausschließlich auf
        unsere Weisung und in unserem Auftrag.
      </p>

      <p>
        <strong>4. Ihre Rechte</strong>
      </p>

      <p>
        Sie haben das Recht, jederzeit Auskunft über die über Sie gespeicherten
        personenbezogenen Daten zu erhalten. Sie haben außerdem das Recht auf
        Übertragung, Berichtigung oder Löschung Ihrer personenbezogenen Daten
        sowie auf Einschränkung der Verarbeitung Ihrer Daten. Sie können auch
        jederzeit der Verarbeitung Ihrer Daten widersprechen. Hierzu im
        Einzelnen:
      </p>

      <p>a.) Recht auf Information</p>

      <p>
        Unter den Bedingungen von Art. 15 DSGVO haben Sie das Recht, von den
        Partnerorganisationen eine Bestätigung darüber zu verlangen, ob die Sie
        betreffenden personenbezogenen Daten verarbeitet werden. Ist dies der
        Fall, so haben Sie auch das Recht auf Auskunft über diese
        personenbezogenen Daten sowie über den Zweck der Verarbeitung, die
        Kategorie der verarbeiteten personenbezogenen Daten, die Empfänger oder
        Kategorien von Empfängern, denen die personenbezogenen Daten offengelegt
        wurden oder werden, die geplante Dauer der Speicherung der
        personenbezogenen Daten, das Bestehen eines Rechts auf Berichtigung oder
        Löschung der Sie betreffenden Daten oder auf Einschränkung der
        Verarbeitung oder auf Widerspruch gegen eine solche Verarbeitung, das
        Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde und, falls
        die personenbezogenen Daten nicht bei Ihnen erhoben wurden, auf alle
        verfügbaren Informationen über die Herkunft der Daten.
      </p>

      <p>b.) Recht auf Berichtigung</p>

      <p>
        Gemäß Art. 16 DSGVO haben Sie außerdem das Recht, unverzüglich die
        Berichtigung oder Vervollständigung aller Sie betreffenden unrichtigen
        personenbezogenen Daten zu verlangen.
      </p>

      <p>c.) Recht auf Löschung</p>

      <p>
        Unter den Voraussetzungen des Art. 17 DSGVO haben Sie das Recht, die
      </p>

      <p>
        Unter den Voraussetzungen des Art. 17 DSGVO haben Sie das Recht, die
        Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu
        verlangen, es sei denn, die Verarbeitung ist zur Ausübung des Rechts auf
        freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
        Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        erforderlich.
      </p>

      <p>d.) Recht auf Verjährung</p>

      <p>
        Unter den Voraussetzungen des Art. 18 GDPR haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer Daten zu verlangen.
      </p>

      <p>e.) Recht auf Übermittlung</p>

      <p>
        Gemäß Art. 20 DSGVO haben Sie das Recht, die Sie betreffenden
        personenbezogenen Daten in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten, sowie das Recht, sofern die
        Voraussetzungen des Art. 20 GDPR erfüllt sind, diese an einen anderen
        Verantwortlichen zu übermitteln. In diesem Zusammenhang haben Sie das
        Recht, Ihre personenbezogenen Daten direkt an den anderen
        Verantwortlichen übermitteln zu lassen.
      </p>

      <p>d.) Recht auf Widerspruch</p>

      <p>
        Gemäss Art. 21 GDPR, wenn Ihre personenbezogenen Daten auf der Grundlage
        von berechtigten Interessen gemäß Art. 6 Abs.. 1 Satz 1 lit. f DSGVO
        verarbeitet werden, haben Sie das Recht, der Verarbeitung Ihrer
        personenbezogenen Daten zu widersprechen, wenn dafür Gründe vorliegen,
        die sich aus Ihrer besonderen Situation ergeben.
      </p>

      <p>e.) Recht auf Beschwerde</p>

      <p>
        Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in irgendeiner Weise verletzt worden sind, können Sie sich bei der
        zuständigen Aufsichtsbehörde beschweren.
      </p>

      <p></p>

      <p></p>
    </div>
  );
};

export default Privacy;
