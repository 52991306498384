import React from "react";
import "./Overview.css";
import image from "./crossviews_image.jpeg";

const Overview: React.FC = () => {
  return (
    <div className="middleleft">
      <h1 className="overviewHeading">Raus aus der Bubble!</h1>
      {/* <div className="overviewSlogan">
        <span>
          <i>
            <strong>crossviews</strong> - Made in Heidelberg 2024
          </i>
        </span>
      </div> */}
      <p>
        Wir waren noch nie so vernetzt wie heute. Doch wann hast Du Dich das
        letzte mal mit jemandem ausgetauscht, der oder die einer anderen Ansicht
        war?
      </p>
      <p>
        Um unseren Horizont zu erweitern und uns wieder zu vernetzen, brauchen
        wir Austausch - über unsere üblichen Kreise hinaus. Auf Crossviews
        kannst Du Gesprächspartner finden, die einer anderen Meinungen sind als
        Du.
      </p>
      <h2>Wie funktioniert Crossviews?</h2>
      <p>
        Du gibst über das Formular deine Position zu einer kurzen Reihe an Fragen an. 
        Wir suchen dann nach einer Person, die möglichst unterschiedlich geantwortet hat.
        Falls ihr beide das Match annehmt, könnt ihr euch kontaktieren und lokal in Heidelberg treffen.
      </p>
      <img src={image} alt="crossviews" className="image" />
      <h2>Warum teilnehmen?</h2>
      <p>
        Trainiere Deine Fähigkeit, anderen Leute zuzuhören und ihnen Deine
        Perspektive näher zu bringen. Trage zu einer Kultur der Offenheit und
        des gegenseitigen Respekts bei. Nutze eine Gelegenheit, Dich auch mit
        deiner eigenen Meinung tiefer auseinanderzusetzen.
      </p>
      <h2>Lust bekommen, zu diskutieren?</h2>
      <p>
        Dann kann es losgehen! Fülle das Formular aus und fang an zu diskutieren.
      </p>

    </div>
  );
};

export default Overview;
